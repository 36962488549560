export const outfield1 = {
  quizTitle: "Outfield Scenarios",
  quizSynopsis: "Learn the outfield... it's important",
  questions: [
    {
      question: "Ball hit to you hard on the ground. What do you do?",
      questionType: "text",
      answers: [
        "Hold the ball",
        "Throw it home",
        "Quickly field the ball and fire to 2nd base",
        "Back up the right fielder"
      ],
      correctAnswer: "3",
      explanation: "Our first choice is to get the lead runner at second if possible. ",
      answerSelectionType: "single",
      point: 50,
      showField: true,
      field: {
        outs: 1,
        focus: "9",
        runners: {
          first: true,
          second: false,
          third: false
        }
      }
    },
    {
      question: "Man at 2nd. Pop fly hit to you. What direction is your first step?",
      questionType: "text",
      answers: ["Sprint forward", "You stand still", "Step backwards as you read the ball"],
      correctAnswer: "3",
      explanation:
        "Our first step is always back as we read the ball. If we see its short we plant hard and sprint forward but it is easier to gain ground running forward than it is running back.",
      answerSelectionType: "single",
      point: 50,
      showField: true,
      field: {
        outs: 1,
        focus: "8",
        runners: {
          first: false,
          second: true,
          third: false
        }
      }
    },
    {
      question: "Ball is hit to the LC fielder. What should you do??",
      questionType: "text",
      answers: [
        "Sprint hard taking a good angle to get behind the LC fielder and back him up",
        "Jog towards the LC fielder",
        "Sprint in front of the LC fielder to take the ball ",
        "Call for the ball even though it is clearly his ball"
      ],
      correctAnswer: "1",
      explanation:
        "Our first job is the ball but our second is just as important.. BACKUP... Backing up our teammates takes effort and although we trust them to make the play we need to be there in case they can't.",
      answerSelectionType: "single",
      point: 50,
      showField: true,
      field: {
        outs: 1,
        focus: "7",
        runners: {
          first: false,
          second: true,
          third: false
        }
      }
    },
    {
      question: "Ball hit to the SS. What should you do?",
      questionType: "text",
      answers: [
        "Backup the 2nd baseman",
        "Stay in your spot",
        "Take a break ",
        "Sprint to backup the throw to 1st base"
      ],
      correctAnswer: "4",
      explanation: "RF has 1st base backup duty on balls hit up the middle or to the left side of the infield.",
      answerSelectionType: "single",
      point: 50,
      showField: true,
      field: {
        outs: 0,
        focus: "10",
        runners: {
          first: false,
          second: false,
          third: false
        }
      }
    },
    {
      question:
        "Pop fly hit in between you and the SS. You know you can make the catch easier than the SS but he is sprinting to the spot. What do you do? ",
      questionType: "text",
      answers: [
        "Stop and stay where you are",
        "Immediately call for the ball loudly so the SS will peel off. Make the catch. ",
        "Let the ball drop in between you and the SS then throw it to 2nd base.",
        "Make an unnecessary sliding catch to impress your friends"
      ],
      explanation:
        "Outfield has priority over the infield on pop flies in the outfield. Never give up on a ball or assume someones else will make the play. ",
      correctAnswer: "2",
      answerSelectionType: "single",
      point: 50,
      showField: true,
      field: {
        outs: 0,
        focus: "8",
        runners: {
          first: true,
          second: false,
          third: false
        }
      }
    },
    {
      question: "Ball hit to you where do you go with it??",
      questionType: "text",
      answers: [
        "Field the ball and throw it to 2nd base",
        "Field the ball and throw it to 3rd base",
        "Throw it to 1st base",
        "Hold it"
      ],
      explanation:
        "Never... ever... hold it. We want to throw it in front of the lead runner to maintain the force and possibly make the force out. Never throw to the base behind the runner.",
      correctAnswer: "2",
      point: 50,
      answerSelectionType: "single",
      showField: true,
      field: {
        outs: 0,
        focus: "7",
        runners: {
          first: true,
          second: true,
          third: false
        }
      }
    }
  ]
};
